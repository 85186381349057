import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
//import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';

const ServersCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"Server Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Server Name"}
                            name='name'
                            required={true}
                            defaultValue={form.defaults.name}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"IP Address"}
                            name='ipAddress'
                            required={true}
                            defaultValue={form.defaults.ipAddress}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldSelect
                            form={form}
                            label={"Provider"}
                            name='provider'
                            required={true}
                            defaultValue={form.defaults.provider}
                            options={[
                                { value: "AWS", label: "AWS" },
                                { value: "Azure", label: "Azure" },
                                { value: "DigitalOcean", label: "DigitalOcean" },
                                { value: "Other", label: "Other" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldNumber
                            form={form}
                            label={"Maximum No. of Apps"}
                            name='maxApps'
                            placeholder={"0 = unlimited"}
                            required={false}
                            defaultValue={form.defaults.maxApps}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 8 }}>

                        <FieldRadio
                            form={form}
                            label={"Status"}
                            name='status'
                            required={true}
                            defaultValue={form.defaults.status}
                            options={[
                                { value: "active", label: "Active" },
                                { value: "shutdown", label: "Shutdown" },
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Notes"}
                            name='notes'
                            defaultValue={form.defaults.notes}
                        />

                    </Col>

                </Row>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default ServersCreateEditForm;
