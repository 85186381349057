import React, { useMemo } from 'react';
import { Col, Row, Space } from '@dex/bubl-dash';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';

import MigrationsSingleDbExport from './MigrationsSingleDbExport';

const MigrationsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Migrations Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Type",
                                key: "type",
                                format: "titleCase"
                            },
                        ]}
                        columnB={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase"
                            },
                        ]}
                        columnC={[
                            {
                                label: "Source Pipeline",
                                key: "sourcePipe",
                                format: "ModelLink"
                            },
                        ]}
                        columnD={[
                            {
                                label: "Destination Pipeline",
                                key: "destPipe",
                                format: "ModelLink"
                            },
                        ]}
                    />

                </Panel>

                {data.type === "database" &&
                    <>

                        <Space />

                        <MigrationsSingleDbExport data={data} />

                    </>
                }

                {data.type === "media" &&
                    <>

                        <Space />

                        <MigrationsSingleDbExport data={data} />

                    </>
                }

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default MigrationsSingleView;
