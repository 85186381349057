import ServersIndex from './ServersIndex';
import ServersCreate from './ServersCreate';
import ServersSettings from './ServersSettings';
import ServersSingle from './ServersSingle';

export default function (app) {

    const settings = ServersSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 13,
        items: [
            {
                label: "All Servers",
                path: settings.path,
                caps: settings.indexCaps
            },
            // {
            //     label: "Active",
            //     path: settings.path + "/status/active",
            //     caps: settings.indexCaps
            // },
            // {
            //     label: "Shutdown",
            //     path: settings.path + "/status/shutdown",
            //     caps: settings.indexCaps
            // },
            {
                label: "Add New Servers",
                path: settings.path + "/create",
                caps: settings.indexCaps
            }
        ],
    });

    ServersIndex(app, settings);

    ServersCreate(app, settings);

    ServersSingle(app, settings);

}