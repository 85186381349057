import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import ServersApi from './ServersApi';

let settings;

export default settings = {
    key: 'servers',
    zone: 'lists',
    path: '/servers',
    title: "Servers",
    endpoint: ServersApi.endpoint,
    modelName: 'Servers',
    idKey: 'id',
    primaryKey: 'name',
    indexCaps: ["viewServers"],
    viewCaps: ["viewServers"],
    createCaps: ["createServers"],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Active",
                path: settings.path + "/status/active",
            },
            {
                label: "Shutdown",
                path: settings.path + "/status/shutdown",
            },
            {
                label: "Add New Server",
                path: settings.path + "/create"
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: ServersApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = { where: {}, include: [], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.status) filters.where.status = { inq: [params.status] };

        return filters;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id', 'name', 'provider', 'ipAddress'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Name",
                key: "this",
                format: "ModelLink",
                sort: 'name'
            },
            {
                label: "Provider",
                key: "provider",
                type: 'text',
                format: "titleCase",
            },
            {
                label: "IP Address",
                key: "ipAddress",
            },
            {
                label: "Notes",
                key: "notes",
                type: "text",
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Name",
                key: "this",
                format: "ModelLink",
                sort: 'name'
            },
            {
                label: "IP Address",
                key: "ipAddress",
            },
            {
                label: "Provider",
                key: "provider",
            },
            {
                label: "Status",
                key: "status",
                format: "titleCase",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: ServersApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: ServersApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = { include: [] };

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
};