import React, { useMemo, useCallback, useEffect } from 'react';
import { Row, Col, Space } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import BuildsApi from '../BuildsApi';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import BuildSingleLogs from './BuildsSingleLogs';
import { Events } from '@dex/bubl-dash';

const BuildsSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    const app = useApp();

    const [nav] = app.store("nav");

    const run = BuildsApi.update(data.id, 'run', {
        onComplete: (response) => {

            app.navigate(nav, 'update', {});

        }
    });

    const onRun = useCallback((continueRun: any) => {

        run.run({
            data: { continueRun: continueRun }
        });

    }, [data.status]);

    useEffect(() => {

        let timer;

        if (!['building', 'running'].includes(data.status)) return;

        timer = setInterval(() => {

            Events.emit('refresh', true);

        }, 6000);

        return () => {

            clearInterval(timer);

        }

    }, [data.status]);

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Builds Details"}
                    actions={(
                        <ButtonRow>

                            {(data.status === "complete") &&

                                <Button
                                    label="Clone"
                                    size="small"
                                    type="faded"
                                    // onClick={onRun.bind(null, true)}
                                    path={"/builds/create?cloneId=" + data.id}
                                />

                            }

                            {(data.status === "error") &&

                                <Button
                                    label="Conitnue"
                                    size="small"
                                    type="faded"
                                    onClick={onRun.bind(null, true)}
                                />

                            }

                            {(data.status === "building") &&

                                <Button
                                    label="Check Status"
                                    size="small"
                                    type="faded"
                                    onClick={onRun.bind(null, true)}
                                />

                            }

                            {(data.status === "qued" || data.status === "error") &&

                                <Button
                                    label="Run"
                                    size="small"
                                    type="primary"
                                    onClick={onRun.bind(null, false)}
                                />

                            }

                        </ButtonRow>

                    )}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Type",
                                key: "type",
                                format: "titleCase",
                            },
                            {
                                label: "Environment",
                                key: "environment",
                                format: "titleCase",
                            },
                            {
                                label: "Duration",
                                key: "duration",
                                format: "number",
                                suffix: " Seconds",
                                decimals: 0,
                            }
                        ]}
                        columnB={[
                            {
                                label: "Repo",
                                key: "repo",
                            },
                            {
                                label: "Branch",
                                key: "branch",
                            },
                        ]}
                        columnC={[
                            {
                                label: "App",
                                key: "app",
                                format: "ModelLink"
                            },
                            {
                                label: "Pipeline",
                                key: "pipe",
                                format: "ModelLink"
                            },
                        ]}
                    />

                </Panel>

                {data.status !== "Qued" &&
                    <>

                        <Space />

                        <BuildSingleLogs data={data} />

                    </>
                }

            </Col>

            <Col className={'side'}>

                <ActivityPanel parent={data} />

            </Col>

        </Row>

    ), [data]);

}

export default BuildsSingleView;
