import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import PipelinesApi from './PipelinesApi';

let settings;

export default settings = {
    key: 'pipelines',
    zone: 'lists',
    path: '/pipelines',
    title: "Pipelines",
    endpoint: PipelinesApi.endpoint,
    modelName: 'Pipelines',
    idKey: 'id',
    primaryKey: 'id',
    actions: ['trash', 'clone'],
    indexCaps: ["viewPipelines"],
    viewCaps: ["viewPipelines"],
    createCaps: ["createPipelines"],
    indexTitle: (routeName, params, user) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, user) => {

        return [
            {
                label: "All",
                path: settings.path
            },
            {
                label: "Testing",
                path: settings.path + "/environment/testing",
            },
            {
                label: "Staging",
                path: settings.path + "/environment/staging",
            },
            {
                label: "Production",
                path: settings.path + "/environment/production",
            },
            {
                label: "Add New Pipeline",
                path: settings.path + "/create"
            },
        ]

    },
    indexButtons: (routeName, params, user) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: PipelinesApi.get,
    indexFetchParams: (routeName, params, user) => {

        const filters: any = { where: {}, include: [], order: ['created.date DESC'], limit: 10, skip: 0 };

        if (params && params.environment) filters.where.environment = { inq: [params.environment] };

        filters.include = [
            {
                relation: "app",
                scope: {
                    fields: ["id", "name", "type", "modelName"]
                }
            },
            {
                relation: "servers",
                scope: {
                    fields: ["id", "name", "modelName"]
                }
            }
        ];

        return filters;

    },
    indexQuickSearch: (routeName, params, user) => {

        const fields = ['id', 'appId', 'environment', 'repo', 'branch'];

        return fields;

    },
    indexSearchFields: (routeName, params, user) => {

        const fields = [
            {
                label: "Pipe ID",
                key: "this",
            },
            {
                label: "App",
                key: "type",
                type: 'text'
            },
            {
                label: "Environment",
                key: "environment",
                type: "text",
                format: "titleCase",
            },
            {
                label: "Repo",
                key: "repo",
                type: 'text'
            },
            {
                label: "Branch",
                key: "branch",
                type: 'text'
            },
            {
                label: "Servers",
                key: "serversIds",
                type: 'relationship',
                model: "Servers",
            },
            {
                label: "Run On Port",
                key: "runOnPort",
                type: 'text'
            },
            {
                label: "Created Date",
                key: "created.date",
                type: "date",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, user) => {

        const columns = [
            {
                label: "Pipe ID",
                key: "this",
                format: "ModelLink",
                sort: 'name'
            },
            {
                label: "App",
                key: "app",
                format: "ModelLink",
            },
            {
                label: "Environment",
                key: "environment",
                type: "text",
                format: "titleCase",
            },
            {
                label: "Type",
                key: "app.type",
            },
            {
                label: "Repo",
                key: "repo",
                type: 'text'
            },
            {
                label: "Branch",
                key: "branch",
                type: 'text'
            },
            {
                label: "Servers",
                key: "servers",
                format: "modelLink",
            },
            {
                label: "Port",
                key: "runOnPort",
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: PipelinesApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: PipelinesApi.getOne,
    singleFetchParams: (routeName, params, user, fetch) => {

        const filters = {
            include: ['app', 'servers', 'deployProtectionUsers', 'notifyOnDeployUsers']
        }

        return filters;

    },
    singleTitle: (routeName, params, user, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return title;

    },
    singleTabs: (routeName, params, user, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit"
            },
        ]

    },
};