import PipelinesIndex from './PipelinesIndex';
import PipelinesCreate from './PipelinesCreate';
import PipelinesSettings from './PipelinesSettings';
import PipelinesSingle from './PipelinesSingle';

export default function (app) {

    const settings = PipelinesSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 10,
        items: [
            {
                label: "All Pipelines",
                path: settings.path,
                caps: settings.indexCaps
            },
            // {
            //     label: "Testing",
            //     path: settings.path + "/environment/testing",
            //     caps: settings.indexCaps
            // },
            // {
            //     label: "Staging",
            //     path: settings.path + "/environment/staging",
            //     caps: settings.indexCaps
            // },
            // {
            //     label: "Production",
            //     path: settings.path + "/environment/production",
            //     caps: settings.indexCaps
            // },
            {
                label: "Add New Pipeline",
                path: settings.path + "/create",
                caps: settings.indexCaps
            }
        ],
    });

    PipelinesIndex(app, settings);

    PipelinesCreate(app, settings);

    PipelinesSingle(app, settings);

}