import React from 'react';
import { titleCase, get } from "@dex/bubl-helpers";
import BuildsApi from './BuildsApi';
import { Status, WhiteSpace } from '@dex/bubl-dash';

let settings;

export default settings = {
    key: 'builds',
    zone: 'lists',
    path: '/builds',
    title: "Builds",
    endpoint: BuildsApi.endpoint,
    modelName: 'Builds',
    idKey: 'id',
    primaryKey: 'id',
    actions: ['trash', 'clone'],
    indexCaps: ["viewBuilds"],
    viewCaps: ["viewBuilds"],
    createCaps: ["createBuilds"],
    updateCaps: ["updateBuilds"],
    indexTitle: (routeName, params, users) => {

        let title: string = settings.title;

        if (params && params.name) title = title + " / " + titleCase(params.name);

        return title;

    },
    indexTabs: (routeName, params, users) => {

        return [
            {
                label: "All",
                path: settings.path,
                caps: settings.indexCaps
            },
            {
                label: "Qued",
                path: settings.path + "/status/qued",
            },
            {
                label: "Running",
                path: settings.path + "/status/running",
            },
            {
                label: "Paused",
                path: settings.path + "/status/paused",
            },
            {
                label: "Error",
                path: settings.path + "/status/error",
            },
            {
                label: "Complete",
                path: settings.path + "/status/complete",
            },
            {
                label: "Add New",
                path: settings.path + "/create",
                caps: settings.createCaps
            },
        ]

    },
    indexButtons: (routeName, params, users) => {

        return [
            {
                label: "Add New",
                path: settings.path + '/create',
                caps: settings.createCaps
            }
        ]

    },
    indexTabCounts: (routeName, params, dontation) => {

        return settings.endpoint + "/getCounts";

    },
    indexFetch: BuildsApi.get,
    indexFetchParams: (routeName, params, users) => {

        const filters: any = { where: {}, include: ["pipe"], order: ['created.date DESC'], limit: 10, skip: 0 }; //, "app"

        if (params && params.status) filters.where.status = { inq: [params.status] };

        filters.fields = ['id', 'modelName', 'pipeId', 'appId', 'type', 'status', 'created', 'updated', 'pipe', 'app', 'duration'];

        return filters;

    },
    indexQuickSearch: (routeName, params, users) => {

        const fields = ['id', 'pipeId', 'type'];

        return fields;

    },
    indexSearchFields: (routeName, params, users) => {

        const fields = [
            {
                label: "ID",
                key: "id",
                type: "text",
            },
        ];

        return fields;

    },

    indexColumns: (routeName, params, users) => {

        const columns = [
            {
                label: "ID",
                key: "this",
                format: "ModelLink",
            },
            {
                label: "Status",
                key: "status",
                format: "status",
                prefix: "builds",
            },
            {
                label: "Pipeline",
                key: "pipe",
                format: "ModelLink",
            },
            // {
            //     label: "App",
            //     key: "app",
            //     format: "ModelLink",
            // },
            {
                label: "Type",
                key: "type",
            },
            {
                label: "Duration",
                key: "duration",
                format: "number",
                suffix: " Seconds",
                decimals: 0,
            },
            {
                label: "Created Date",
                key: "created",
                format: "OnAndBy",
                sort: "created.date",
                alignEnd: true
            },
            {
                label: "Actions",
                key: "actions",
                alignEnd: true,
                format: "actions",
                api: BuildsApi,
                settings: settings,
            }
        ];

        return columns;

    },
    singleFetch: BuildsApi.getOne,
    singleFetchParams: (routeName, params, users, fetch) => {

        const filters = { include: ["pipe", "app"] };

        return filters;

    },
    singleTitle: (routeName, params, users, fetch) => {

        let title: string = "Loading...";

        const complete = get(fetch, 'complete');
        const error = get(fetch, 'error');
        const data = get(fetch, 'data');

        if (error) title = "Error";
        else if (data) title = data[settings.primaryKey];
        else if (complete && !data) title = "Not Found";

        return <>{title}<WhiteSpace /><Status prefix={"builds"} status={data?.status} /></>;


    },
    singleTabs: (routeName, params, users, fetch) => {

        const path = settings.path + "/" + params.id;

        return [
            {
                label: "View",
                path: path + "/view"
            },
            {
                label: "Edit",
                path: path + "/edit",
                caps: settings.updateCaps
            },
        ]

    },
};