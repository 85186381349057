import React from 'react';

import BublDash from '@dex/bubl-dash/src/lib/Dash';
import Media from '@dex/bubl-dash/src/lib/views/Media/Media';
import Users from '@dex/bubl-dash/src/lib/views/Users/Users';
import Defaults from '@dex/bubl-dash/src/lib/views/Defaults';
import Wrapper from '@dex/bubl-dash/src/lib/Wrapper';

import Servers from '../src/views/Servers/Servers';
import Apps from '../src/views/Apps/Apps';
import Builds from '../src/views/Builds/Builds';
import Pipelines from '../src/views/Pipelines/Pipelines';

import './styles/App.scss';
import Migrations from './views/Migrations/Migrations';

const app = new BublDash({
    name: "Deploy",
    icon: "assets/logo.png",
    accessRoles: ['admin', 'developer', 'super'], //must be one of these roles in order to sign in
    userRoles: [
        // {
        //     key: 'client',
        //     label: 'Client'
        // },
        {
            key: 'developer',
            label: 'Developer'
        },
        {
            key: 'admin',
            label: 'Admin'
        },
        {
            key: 'super',
            label: 'Super'
        }
    ]
});

app.addRoute({
    name: "home",
    path: "/",
    component: () => <></>,
    componentProps: {},
    caps: [],
});

app.addZone({
    key: "monitor",
    label: "Monitor",
    priority: 10,
    //icon:
});

//Add New Models Here
const extensions: any = [
    Defaults,
    Users,
    Apps,
    Migrations,
    Builds,
    Pipelines,
    Servers,
    Media
];

extensions.map(extension => {

    extension(app);

    return null;

});

const App: React.FC = () => {

    return (
        <Wrapper>
            {app.render()}
        </Wrapper>
    )

}

export default App;