import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import AppsCreateEditForm from './Components/AppsCreateEditForm';
import AppsApi from './AppsApi';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New App",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: AppsCreateEditForm,
                getCreate: AppsApi.create,
                getClone: AppsApi.getOne,
                defaults: { variables: [] },
                successMessage: "App Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey
            }
        },
    });

}