import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';

const ServersSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Server Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "Name",
                                key: "name",
                            },
                            {
                                label: "IP Address",
                                key: "ipAddress",
                            }
                        ]}
                        columnB={[
                            {
                                label: "Provider",
                                key: "provider",
                            },
                            {
                                label: "Maximum Number of Apps",
                                key: "maxApps",
                                format: "number"
                            }
                        ]}
                        columnC={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase"
                            },
                            {
                                label: "Notes",
                                key: "notes",
                            }

                        ]}
                    />

                </Panel>

            </Col>

            <Col
                className={'side'}
            >

                <ActivityPanel
                    parent={data}
                />

            </Col>

        </Row>

    ), [data]);

}

export default ServersSingleView;
