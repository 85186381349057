import React, { useMemo, useCallback } from 'react';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';
import { useForm } from '@dex/bubl-dash/src/lib/functions/useForm';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import MigrationsApi from '../MigrationsApi';

const MigrationsSingleDbExport: React.FC<any> = (props: any) => {

    const app = useApp();

    const [nav] = app.store("nav");

    const { data } = props;

    const form = useForm(data);

    const runExport = MigrationsApi.update(data.id, "runExportDb", {
        timeout: 60000,
        onComplete: (data) => {

            app.navigate(nav, 'update', {});

        }
    });

    const runImport = MigrationsApi.update(data.id, "runImportDb", {
        timeout: 60000,
        onComplete: (data) => {

            app.navigate(nav, 'update', {});

        }
    });

    const runClear = MigrationsApi.update(data.id, "runClearDb", {
        onComplete: (data) => {

            app.navigate(nav, 'update', {});

        }
    });

    return useMemo(() => (

        <Panel
            heading={"Migrate Database Actions"}
        >

            <ButtonRow>
                <Button
                    label={"Export Database"}
                    onClick={runExport.run}
                    loading={runExport.loading}
                />
                <Button
                    label={"Import Database"}
                    onClick={runImport.run}
                    loading={runImport.loading}
                />
                <Button
                    label={"Clear Local Files"}
                    onClick={runClear.run}
                    loading={runClear.loading}
                    type={"faded"}
                />
            </ButtonRow>

        </Panel>

    ), [data, form, runExport, runImport, runClear]);

}

export default MigrationsSingleDbExport;
