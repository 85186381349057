import React, { useMemo } from 'react';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { titleCase } from '@dex/bubl-helpers';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';
import BuildsSingleStep from './BuildsSingleStep';

const BuildSingleLogs: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Panel
            heading={"Deploy Logs"}
        >

            {data.steps.map((step, index) => (

                <React.Fragment key={index}>

                    <BuildsSingleStep data={data} index={index} step={step} />

                </React.Fragment>

            ))}

        </Panel >

    ), [data]);

}

export default BuildSingleLogs;
