import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import MetaTable from '@dex/bubl-dash/src/lib/components/MetaTable/MetaTable';
import ActivityPanel from '@dex/bubl-dash/src/lib/components/SinglePanels/ActivityPanel';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';

const PipelinesSingleView: React.FC<any> = (props: any) => {

    const { data } = props;

    return useMemo(() => (

        <Row
            gutter={'auto'}
        >

            <Col
                className={'main'}
            >

                <Panel
                    heading={"Pipeline Details"}
                >

                    <MetaTable
                        data={data}
                        columnA={[
                            {
                                label: "App",
                                key: "app",
                                format: "ModelLink"
                            },
                            {
                                label: "Environment",
                                key: "environment",
                                format: "titleCase",
                            },
                            {
                                label: "Repo",
                                key: "repo",
                            },
                            {
                                label: "Branch",
                                key: "branch",
                            }
                        ]}
                        columnB={[
                            {
                                label: "Status",
                                key: "status",
                                format: "titleCase",
                            },
                            {
                                label: "Servers",
                                key: "servers",
                                format: "modelLink",
                            },
                            {
                                label: "Run On Port",
                                key: "runOnPort",
                            },
                        ]}
                        columnC={[
                            {
                                label: "Unit Tests",
                                key: "unitTests",
                                format: "switch",
                            },
                            {
                                label: "Deploy Protection",
                                key: "deployProtectionUsers",
                                format: "modelLink",
                            },
                            {
                                label: "Notify On Deploy",
                                key: "notifyOnDeployUsers",
                                format: "modelLink",
                            },
                            {
                                label: "Snyk Token",
                                key: "snykToken",
                                format: "text",
                            }
                        ]}
                    />

                    <Seperator
                        heading={"Variables"}
                        top={"auto"}
                        bottom={"auto"}
                    />

                    <MetaTable
                        data={data.variables || []}
                        columnA={[
                            {
                                label: "Key",
                                key: "key",
                            },
                        ]}
                        columnB={[
                            {
                                label: "Value",
                                key: "value",
                            },
                        ]}
                    />

                </Panel>

            </Col>

            <Col
                className={'side'}
            >

                <ActivityPanel
                    parent={data}
                />

            </Col>

        </Row>

    ), [data]);

}

export default PipelinesSingleView;
