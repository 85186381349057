import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import FieldRelationship from '@dex/bubl-dash/src/lib/components/Fields/FieldRelationship';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
import FieldSwitch from '@dex/bubl-dash/src/lib/components/Fields/FieldSwitch';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import FieldSelect from '@dex/bubl-dash/src/lib/components/Fields/FieldSelect';
import FieldNumber from '@dex/bubl-dash/src/lib/components/Fields/FieldNumber';
import { useApp } from '@dex/bubl-dash/src/lib/functions/useApp';

const PipelinesCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit, mode } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    const app = useApp();

    const [user] = app.store("currentUser");

    const envoirements = [
        { value: "testing", label: "Testing" },
        { value: "staging", label: "Staging" },
    ];

    if (app.userCan(["createProdPipelines"], user)) {

        envoirements.push({ value: "production", label: "Production" });

    }

    return useMemo(() => (

        <Panel
            heading={"Pipeline Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRelationship
                            form={form}
                            label={"App"}
                            name='appId'
                            required={true}
                            preload={true}
                            endpoint={'Apps'}
                            defaultValue={form.defaults.appId}
                            isDisabled={mode !== "create"}
                        />

                        <FieldSelect
                            form={form}
                            label={"Environment"}
                            name='environment'
                            required={true}
                            defaultValue={form.defaults.environment}
                            options={envoirements}
                            isDisabled={mode !== "create"}
                        />

                        <FieldText
                            form={form}
                            label={"Repo"}
                            name='repo'
                            required={true}
                            defaultValue={form.defaults.repo}
                        />

                        <FieldText
                            form={form}
                            label={"Branch"}
                            name='branch'
                            required={true}
                            defaultValue={form.defaults.branch}
                        />

                        <FieldRelationship
                            form={form}
                            label={"Deploy On Servers"}
                            name='serversIds'
                            required={true}
                            preload={true}
                            isMulti={true}
                            endpoint={'Servers'}
                            defaultValue={form.defaults.serversIds}
                        />

                        <FieldText
                            form={form}
                            label={"Run On Port"}
                            name='runOnPort'
                            required={true}
                            defaultValue={form.defaults.runOnPort}
                        />

                        <FieldNumber
                            form={form}
                            label={"Instance Count"}
                            name='instanceCount'
                            required={true}
                            defaultValue={form.defaults.instanceCount}
                            min={1}
                            max={10}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRadio
                            form={form}
                            label={"Status"}
                            name='status'
                            required={true}
                            defaultValue={form.defaults.status}
                            options={[
                                { value: "active", label: "Active" },
                                { value: "inactive", label: "Inactive" }
                            ]}
                        />

                        <FieldSwitch
                            form={form}
                            label={"Unit Tests"}
                            name='unitTests'
                            defaultValue={form.defaults.unitTests}
                        />

                        <FieldSwitch
                            form={form}
                            label={"Deploy Protection"}
                            name='deployProtection'
                            defaultValue={form.defaults.deployProtection}
                        />

                        {form.values.deployProtection &&

                            <FieldRelationship
                                form={form}
                                label={"Deploy Protection Users"}
                                name='deployProtectionIds'
                                required={true}
                                preload={true}
                                isMulti={true}
                                endpoint={'Users'}
                                defaultValue={form.defaults.deployProtectionIds}
                            />

                        }

                        <FieldSwitch
                            form={form}
                            label={"Notify On Deploy"}
                            name='notifyOnDeploy'
                            defaultValue={form.defaults.notifyOnDeploy}
                        />

                        {form.values.notifyOnDeploy &&

                            <FieldRelationship
                                form={form}
                                label={"Notify Users"}
                                name='notifyOnDeployIds'
                                required={true}
                                preload={true}
                                isMulti={true}
                                endpoint={'Users'}
                                defaultValue={form.defaults.notifyOnDeployIds}
                            />

                        }

                        <FieldSwitch
                            form={form}
                            label={"Enable Snyk Test"}
                            name='enableSnykTest'
                            defaultValue={form.defaults.enableSnykTest}
                        />

                        {form.values.enableSnykTest &&


                            <FieldText
                                form={form}
                                label={"Snyk Token"}
                                name='snykToken'
                                required={true}
                                defaultValue={form.defaults.snykToken}
                            />

                        }

                    </Col>

                </Row>

                <Seperator
                    heading={"Variables"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <FieldRepeater
                    form={form}
                    name={'variables'}
                    addButtonLabel={"Add Variable"}
                >

                    {form.values.variables.map((row, n) => (

                        <React.Fragment key={form.key + "-" + n}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldText
                                        form={form}
                                        label={"Key"}
                                        name={`variables[${n}].key`}
                                        required={true}
                                        defaultValue={row.key}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldText
                                        form={form}
                                        label={"Value"}
                                        name={`variables[${n}].value`}
                                        required={true}
                                        defaultValue={row.value}
                                    />

                                </Col>

                            </Row>

                        </React.Fragment>

                    ))}

                </FieldRepeater>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default PipelinesCreateEditForm;
