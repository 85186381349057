import React, { useMemo } from 'react';
import { Row, Col } from '@dex/bubl-dash/src/lib/components/Layout/Layout';
import Panel from '@dex/bubl-dash/src/lib/components/Panel/Panel';
import { Form } from '@dex/bubl-dash/src/lib/functions/useForm';
import FieldText from '@dex/bubl-dash/src/lib/components/Fields/FieldText';
import FieldRepeater from '@dex/bubl-dash/src/lib/components/Fields/FieldRepeater';
import Seperator from '@dex/bubl-dash/src/lib/components/Seperator/Seperator';
import Line from '@dex/bubl-dash/src/lib/components/Line/Line';
import Button, { ButtonRow } from '@dex/bubl-dash/src/lib/components/Button/Button';
//import Alert from '@dex/bubl-dash/src/lib/components/Alert/Alert';
import FieldTextArea from '@dex/bubl-dash/src/lib/components/Fields/FieldTextArea';
import FieldRadio from '@dex/bubl-dash/src/lib/components/Fields/FieldRadio';

const AppsCreateEditForm: React.FC<any> = (props: any) => {

    const { form, process, handleSubmit } = props;

    // eslint-disable-next-line
    let data = props.data || {};

    return useMemo(() => (

        <Panel
            heading={"App Details"}
        >

            <Form
                form={form}
                onSubmit={handleSubmit}
                loading={process.loading}
            >

                <Row gutter={8} edge={true}>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"App ID"}
                            name='id'
                            disabled={props.mode === "edit"}
                            format="slug"
                            required={true}
                            defaultValue={form.defaults.id}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldText
                            form={form}
                            label={"Name"}
                            name='name'
                            required={true}
                            defaultValue={form.defaults.name}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRadio
                            form={form}
                            label={"Type"}
                            name='type'
                            required={true}
                            defaultValue={form.defaults.type}
                            options={[
                                { value: "BUBL-API", label: "BUBL-API" },
                                { value: "BUBL-DASH", label: "BUBL-DASH" },
                                { value: "BUBL-WEB", label: "BUBL-WEB" },
                                { value: "BUBL-SCRIPT", label: "BUBL-SCRIPT" },
                                // { value: "MELD", label: "MELD" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24, md: 12 }}>

                        <FieldRadio
                            form={form}
                            label={"Status"}
                            name='status'
                            required={true}
                            defaultValue={form.defaults.status}
                            options={[
                                { value: "active", label: "Active" },
                                { value: "inactive", label: "Inactive" }
                            ]}
                        />

                    </Col>

                    <Col col={{ xs: 24 }}>

                        <FieldTextArea
                            form={form}
                            label={"Notes"}
                            name='notes'
                            defaultValue={form.defaults.notes}
                        />

                    </Col>

                </Row>

                <Seperator
                    heading={"Variables"}
                    top={"auto"}
                    bottom={"auto"}
                />

                <FieldRepeater
                    form={form}
                    name={'variables'}
                    addButtonLabel={"Add Variable"}
                >

                    {form.values.variables.map((row, n) => (

                        <React.Fragment key={form.key + "-" + n}>

                            <Row gutter={8} edge={true}>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldText
                                        form={form}
                                        label={"Key"}
                                        name={`variables[${n}].key`}
                                        required={false}
                                        defaultValue={row.key}
                                    />

                                </Col>

                                <Col col={{ xs: 24, md: 12 }}>

                                    <FieldText
                                        form={form}
                                        label={"Value"}
                                        name={`variables[${n}].value`}
                                        required={false}
                                        defaultValue={row.value}
                                    />

                                </Col>

                            </Row>

                        </React.Fragment>

                    ))}

                </FieldRepeater>

                <Line />

                <ButtonRow>
                    <Button
                        label={"Submit"}
                        loading={process.loading}
                    />
                    <Button
                        label={"Reset"}
                        type={"faded"}
                        onClick={form.reset}
                    />
                </ButtonRow>

            </Form>

        </Panel>


    ), [props]);

}

export default AppsCreateEditForm;
