import AppsIndex from './AppsIndex';
import AppsCreate from './AppsCreate';
import AppsSettings from './AppsSettings';
import AppsSingle from './AppsSingle';

export default function (app) {

    const settings = AppsSettings;

    app.addModel({
        name: settings.key,
        path: settings.path,
        modelName: settings.modelName,
        endpoint: settings.endpoint,
        idKey: settings.idKey,
        primaryKey: settings.primaryKey,
    });

    app.addMenu({
        key: settings.key,
        zone: settings.zone,
        label: settings.title,
        priority: 12,
        items: [
            {
                label: "All Apps",
                path: settings.path,
                caps: settings.indexCaps
            },
            // {
            //     label: "Active",
            //     path: settings.path + "/status/active",
            //     caps: settings.indexCaps
            // },
            // {
            //     label: "Inactive",
            //     path: settings.path + "/status/inactive",
            //     caps: settings.indexCaps
            // },
            {
                label: "Add New Apps",
                path: settings.path + "/create",
                caps: settings.indexCaps
            }
        ],
    });

    AppsIndex(app, settings);

    AppsCreate(app, settings);

    AppsSingle(app, settings);

}