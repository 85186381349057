import IndexPage from '@dex/bubl-dash/src/lib/pages/IndexPage';
import BuildsCreateEditForm from './Components/BuildsCreateEditForm';
import BuildsApi from './BuildsApi';

export default function (app, settings) {

    app.addRoute({
        name: settings.key + "New",
        zone: settings.zone,
        path: settings.key + "/create",
        caps: settings.createCaps,
        component: IndexPage,
        componentProps: {
            header: {
                getTitle: () => "Add New",
                getButtons: settings.indexButtons,
                getTabs: settings.indexTabs,
                getTabCounts: settings.indexTabCounts,
            },
            create: {
                form: BuildsCreateEditForm,
                getCreate: BuildsApi.create,
                getClone: BuildsApi.getOne,
                defaults: { environment: "staging" },
                successMessage: "Build Saved",
                successRouteName: settings.key + "View",
                idKey: settings.idKey
            }
        },
    });

}