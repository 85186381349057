import React, { useMemo } from 'react';
import { titleCase } from '@dex/bubl-helpers';
import DataTable from '@dex/bubl-dash/src/lib/components/DataTable/DataTable';
import { Col, MetaTable, Row, Seperator, Status } from '@dex/bubl-dash';

const BuildsSingleStep: React.FC<any> = (props: any) => {

    const { data, step, index } = props;

    const isSnykReportExist = data.snykReport && data.snykReport.length > 0;

    const columns = [
        {
            label: "ID",
            key: "id",
            style: {
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                maxWidth: 100
            }
        },
        {
            label: "Title",
            key: "title",
            style: {
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                maxWidth: 150
            }
        },
        {
            label: "Severity",
            key: "severity",
        },
        {
            label: "Malicious",
            key: "malicious",
            format: "switch",
        },
        {
            label: "Version",
            key: "version",
        },
        {
            label: "Current Version",
            key: "fixedIn[0]",
        },
        {
            label: "Name",
            key: "name",
            style: {
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                maxWidth: 100
            }
        },

    ];

    return useMemo(() => (

        <>

            <Seperator
                top={index ? "auto" : null}
                bottom={"auto"}
                heading={<Row>
                    <Col grow={1}>
                        {titleCase(step.type)}
                    </Col>
                    <Col>
                        <Status prefix={"builds"} status={step.status} />
                    </Col>
                </Row>}
            />

            <MetaTable
                data={step}
                columnA={[
                    {
                        key: "duration",
                        label: "Duration",
                        format: "number",
                        suffix: " Seconds",
                        decimals: 0,
                    }
                ]}
                columnB={[
                    step.type === "init" ? {
                        key: "runner_job_id",
                        label: "Runner ID",
                        render: (value, row) => (
                            <a target='_blank' href={data.runner_url}>Gitlab - {data.runner_id}</a>
                        )
                    } : null
                ].filter(Boolean)}
            />

            {typeof step.log === "string" &&
                <div style={{ maxHeight: 420, overflow: 'auto' }}>
                    <pre>
                        {step.log}
                    </pre>
                </div>
            }

            {(step.type === "snyk" && isSnykReportExist) &&
                <DataTable
                    data={data.snykReport}
                    columns={columns}
                    loading={false}
                />
            }

        </>

    ), [data]);

}

export default BuildsSingleStep;
